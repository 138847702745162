import type { PartnerConfig } from "../partner.type";

import { MergeWhitelabelConfig } from "../default";

export const config: PartnerConfig = {
  // Unique identifier for whitelabel
  key: "checkmybus",
  // Settings used to determine whitelabel behavior
  // IMPORTANT: This will be also be sent to the browser,
  // so do not use it to store any secrets
  whitelabel_settings: MergeWhitelabelConfig(),
  // Map all of partner's locales to a Busbud locale, when available
  // If no matching locale, will fallback to default locale
  locale_mapping: {},
  fallback_locale: "en",
  webpack_config: {
    theme_import: () =>
      import(
        /* webpackChunkName: "theme-checkmybus" */
        "@busbud/design-system-components/dist/themes/checkmybus"
      ),
    supported_locales: []
  }
};
