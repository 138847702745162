import type { PartnerConfig } from "../partner.type";

import { MergeWhitelabelConfig } from "../default";

export const config: PartnerConfig = {
  // Unique identifier for whitelabel
  key: "recorrido",
  // Settings used to determine whitelabel behavior
  // IMPORTANT: This will be also be sent to the browser,
  // so do not use it to store any secrets
  whitelabel_settings: MergeWhitelabelConfig({
    napi_hosts: {
      "local.recorrido.cl": "preview-recorrido.busbud.com/napi",
      "preview-recorrido.busbud.com": "preview-recorrido.busbud.com/napi",
      "consolidation.recorrido.club": "consolidation.recorrido.club/napi",
      "recorrido.busbud.com": "www.recorrido.cl/napi",
      "www.recorrido.cl": "www.recorrido.cl/napi"
    },
    features_override: {
      ADULT_ONLY_SEARCH: true,
      ASYNC_CART_SHOULD_HIDE_DISCOUNT_CODE: true,
      ASYNC_CART_SHOULD_HIDE_TERMS_AND_CONDITIONS: true,
      ASYNC_CART_SINGLE_PAGE_PAYMENT_ENABLED: true,
      CHECKOUT_SHOULD_HIDE_TICKET_OVERVIEW_BLOCK: true,
      CHECKOUT_PASSENGER_SUBHEADER_INFO: "seat",
      DISPLAY_NAME: "Recorrido",
      GTM_ID: null,
      HIDE_HEADER_CURRENCY_SELECTOR: true,
      HIDE_LOGIN_AND_HELP_BUTTONS: true,
      HIDE_DATE_TAB_PRICE: true,
      HOTJAR_ID: "5183629",
      RESULTS_FILTERS_MAX_PRICE: true,
      SEARCH_FORM_DISABLE_LOCATION_INPUTS_ON_RESULTS: true,
      RESULTS_DEPARTURE_CARD: "operator",
      RESULTS_FILTERS_SHOW_CLASSES: true,
      RESULTS_SORTING_TYPE: "classes",
      SEAT_SELECTION_DISPLAY_MULTIPLE_CLASSES: true,
      SEAT_SELECTION_FIRST: true,
      SHOULD_HIDE_MOBILE_APPS_PROMOTION: true,
      SHOW_PAYPAL: false,
      TOP_COUNTRIES_LIST_DEFAULT_COUNTRY_CODE: "CHL",
      TOP_COUNTRIES_LIST_ADD_USER_COUNTRY: false,
      TOP_COUNTRIES_LIST: ["CHL"]
    },
    currency: "CLP",
    partner_favicons: true
  }),
  // Map all of partner's locales to a Busbud locale, when available
  // If no matching locale, will fallback to default locale
  locale_mapping: {},
  fallback_locale: "es",
  webpack_config: {
    theme_import: () =>
      import(
        /* webpackChunkName: "theme-recorrido" */
        "@busbud/design-system-components/dist/themes/recorrido"
      ),
    supported_locales: []
  }
};
