import type { PartnerConfig } from "../partner.type";

import { MergeWhitelabelConfig } from "../default";

export const config: PartnerConfig = {
  // Unique identifier for whitelabel
  key: "ahumada",
  // Settings used to determine whitelabel behavior
  // IMPORTANT: This will be also be sent to the browser,
  // so do not use it to store any secrets
  whitelabel_settings: MergeWhitelabelConfig({
    features_override: {
      ADULT_ONLY_SEARCH: true,
      ASYNC_CART_SHOULD_HIDE_DISCOUNT_CODE: true,
      ASYNC_CART_SHOULD_HIDE_TERMS_AND_CONDITIONS: true,
      CHECKOUT_SHOULD_HIDE_TICKET_OVERVIEW_BLOCK: true,
      CHECKOUT_PASSENGER_SUBHEADER_INFO: "seat",
      DISPLAY_NAME: "Ahumada Internacional",
      HIDE_HEADER_CURRENCY_SELECTOR: true,
      HIDE_LOGIN_AND_HELP_BUTTONS: true, // split in 2
      HIDE_DATE_TAB_PRICE: true,
      RESULTS_DEPARTURE_CARD: "operator",
      RESULTS_FILTERS_MAX_PRICE: true,
      SEAT_SELECTION_DISPLAY_MULTIPLE_CLASSES: true,
      SEAT_SELECTION_FIRST: true,
      SHOULD_HIDE_MOBILE_APPS_PROMOTION: true,
      TOP_COUNTRIES_LIST_DEFAULT_COUNTRY_CODE: "ARG",
      TOP_COUNTRIES_LIST_ADD_USER_COUNTRY: false,
      TOP_COUNTRIES_LIST: ["CHL", "ARG"]
    },
    currency: "ARS",
    billing_address_form: true
  }),
  // Map all of partner's locales to a Busbud locale, when available
  // If no matching locale, will fallback to default locale
  locale_mapping: {},
  fallback_locale: "es",
  webpack_config: {
    theme_import: () =>
      import(
        /* webpackChunkName: "theme-ahumada" */
        "@busbud/design-system-components/dist/themes/ahumada"
      ),
    supported_locales: []
  }
};
