import theme_bb from "@busbud/design-system-components/dist/themes/busbud";
import type { Theme } from "@busbud/design-system-components/dist/types";

import { isNotNil } from "@app/helpers/is-not-nil";
import { lazySentryCaptureException } from "@app/helpers/sentry-capture";

import { PARTNERS } from "@partners/index";

import type { Partner, Whitelabel } from "@partners/partner.type";

export { theme_bb };

type ThemeName = "busbud" | Partner;

interface Themes extends Record<ThemeName, () => Promise<{ default: Theme }>> {}

const THEMES = Object.entries(PARTNERS).reduce<Themes>(
  (themes, [partner, { webpack_config }]) => {
    if (webpack_config.theme_import) {
      themes[partner as ThemeName] = webpack_config.theme_import;
    }
    return themes;
  },
  {} as Themes
) satisfies Themes;

const loadTheme = async (themeName: ThemeName | (string & {})) => {
  if (themeName === "busbud") {
    return theme_bb;
  }
  const isDSCLSupportedWhitelabelTheme = themeName in THEMES;
  const selected_theme_loader = isDSCLSupportedWhitelabelTheme
    ? THEMES[themeName as ThemeName]
    : THEMES["generic"];
  try {
    const themeModule = await selected_theme_loader();
    return themeModule.default;
  } catch (error) {
    lazySentryCaptureException(error);
    throw error;
  }
};

export const getDSCLThemeFromWhitelabel = async (
  whitelabel: Whitelabel | "busbud" | string
): Promise<Theme> => {
  if (!whitelabel || whitelabel === "busbud") {
    return theme_bb;
  }
  return await loadTheme(whitelabel);
};

export const getWhitelabelName = (whitelabel: Whitelabel) => {
  return whitelabel === "" || whitelabel === undefined ? "busbud" : whitelabel;
};

export interface FontLink {
  url: string;
  type: string;
}

export function getFontFaceLinks(theme: Theme): FontLink[] {
  const font_face = theme.busbud.typography?.fontFace.resources;
  if (!font_face) {
    return [];
  }

  const font_families = Object.keys(font_face);

  return font_families
    .flatMap(family => {
      const font = font_face[family];
      return {
        url: font["woff2"],
        type: "font/woff2"
      };
    })
    .filter(isNotNil);
}
